/*=====================================
=            Route Filters            =
=====================================*/

define('routers/filters',[], function () {

    'use strict';

    var filters = {};

    filters.project = function () {

    };

    return filters;

});
