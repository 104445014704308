define('views/ContactView',[
    'views/PageView',
    'text!templates/contact.html',
    'lodash',
    'views/ContactFormView'
], function (PageView, tmpl, _, ContactFormView) {

    'use strict';

    return PageView.extend({

        template: _.template(tmpl),

        render: function () {
            this._render();
            this.form = new ContactFormView();
            this.$el.find('.contact-form').append(this.form.el);
        },

        remove: function () {
            this.form.remove();
            this._remove();
        }

    });

});

