define('views/SubscribeFormView',[
    'views/FormView',
    'models/Subscrs',
    'app',
    'lib/Modal',
    'text!templates/subscribe-form-modal.html',
    'lodash',
    'backbone'
], function (FormView, Subscrs, app, Modal, modalTmpl, _, Backbone) {

    'use strict';

    return FormView.extend({

        modalTemplate: _.template(modalTmpl),

        initialize: function () {
            this.model = new Subscrs();
            this.modal = new Modal({
                hash: ['#subscribe'],
                html: this.modalTemplate(app),
                classnames: 'medium'
            });
        },

        onSuccess: function () {
            this.modal.show();
        },

        onFail: function () {

        },

        remove: function () {
            this.modal.destroy();
            Backbone.View.prototype.remove.call(this);
        }

    });

});

