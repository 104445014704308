define('lib/MouseAnims',[], function () {

    'use strict';

    var move = {
        mousePos: {x: 0, y: 0},
        mouseSrnPos: {x: 0, y: 0},
        scrolled: {x: 0, y: 0}
    };

    var screenDimen = {w: 0, h: 0};

    var moved = false;

    var pause = false;

    function onScroll () {
        move.scrolled = getScrolled();
        onMove();
    }

    function onMousemove (event) {
        move.mouseSrnPos = {
            x: event.clientX,
            y: event.clientY
        };
        onMove();
    }

    function onResize () {
        screenDimen = {
            w: window.innerWidth,
            h: window.innerHeight
        };

        onScroll();
        for (var i = 0; i < insts.length; i++) {
            insts[i].onResize();
        }
        onMove();
    }

    function onMove () {
        move.mousePos = {
            x: move.mouseSrnPos.x + move.scrolled.x,
            y: move.mouseSrnPos.y + move.scrolled.y
        };
        moved = true;
    }

    function frame () {
        if (pause) return;

        if (moved) {
            for (var i = 0; i < insts.length; i++) {
                insts[i].render();
            }
        }

        moved = false;

        // next frame
        window.requestAnimationFrame(frame);
    }

    /**
     * Start rendering loop
     */
    function start () {
        pause = false;

        // attach events
        window.addEventListener('scroll', onScroll);
        window.addEventListener('mousemove', onMousemove);
        window.addEventListener('resize', onResize);
        onResize();

        frame();
    }

    /**
     * Stop rendering loop
     */
    function stop () {
        pause = true;

        // remove events
        window.removeEventListener('scroll', onScroll);
        window.removeEventListener('mousemove', onMousemove);
        window.removeEventListener('resize', onResize);
    }

    /**
     * Array to hold MouseAnims instances
     * @type {Array}
     */
    var insts = [];

    function MouseAnims () {
        this.anims = [];

        // Start the rendering loop
        insts.push(this);
        if (insts.length === 1) {
            start();
        }
    }

    MouseAnims.prototype = {

        add: function (fn, el) {
            this.anims.push(new Anim(fn, el));
        },

        remove: function (anim) {
            this.anims.splice(this.anims.indexOf(anim), 1);
        },

        render: function () {
            for (var i = 0; i < this.anims.length; i++) {
                this.anims[i].render();
            }
        },

        onResize: function () {
            for (var i = 0; i < this.anims.length; i++) {
                this.anims[i].updateRect();
            }
        },

        destroy: function () {
            // remove this from instances list
            insts.splice(insts.indexOf(this), 1);

            // stop rendering loop if necessary
            if (!insts.length) {
                stop();
            }
        }

    };

    function Anim (fn, el) {
        this.el = el;
        this.fn = fn;
        this.updateRect();
    }

    Anim.prototype = {

        rect: null,

        /**
         * Render Animation
         */
        render: function () {
            this.fn(move, this);
        },

        /**
         * Test if element shows up on screen
         * @return {Boolean}
         */
        isOnscreen: function () {
            var scrolled = this.scrolled(),
                x = scrolled.x >= -screenDimen.w && scrolled.x <= this.rect.width,
                y = scrolled.y >= -screenDimen.h && scrolled.y <= this.rect.height;

            return x && y;
        },

        /**
         * Test if mousePos is inside element bounding rect
         * @return {Boolean}
         */
        isInRect: function () {
            var mouse = this.mousePos(),
                x = mouse.x >= 0 && mouse.x <= this.rect.width,
                y = mouse.y >= 0 && mouse.y <= this.rect.height;

            return x && y;
        },

        /**
         * Get mouse position relative to the element
         * @return {object}
         */
        mousePos: function () {
            return {
                x: move.mousePos.x - this.rect.left,
                y: move.mousePos.y - this.rect.top
            };
        },

        /**
         * Get scroll position relative to the element
         * @return {object}
         */
        scrolled: function () {
            return {
                x: move.scrolled.x - this.rect.left,
                y: move.scrolled.y - this.rect.top
            };
        },

        /**
         * Update Element Bounding Rect
         */
        updateRect: function () {
            if (this.el) {
                this.rect = getRect(this.el, move.scrolled);
            }
        }

    };

    /**
     * Helpers
     */

    function getRect (el, scrolled) {
         var rect = el.getBoundingClientRect();

         return {
             bottom: rect.bottom + scrolled.y,
             height: rect.height,
             left: rect.left + scrolled.x,
             right: rect.right + scrolled.x,
             top: rect.top + scrolled.y,
             width: rect.width
         };
     }

    function getScrolled () {
        if (typeof window.scrollX !== 'undefined') {
            return {
                x: window.scrollX,
                y: window.scrollY
            };
        } else {
            return {
                x: window.pageXOffset,
                y: window.pageYOffset
            };
        }
    }

    /*
    function smoothXY (from, to) {
       var diffX = (to.x - from.x) / 4,
           diffY = (to.y - from.y) / 4;

       return {
           x: from.x + ( diffX >= 0 ? Math.ceil(diffX) : Math.floor(diffX) ),
           y: from.y + ( diffY >= 0 ? Math.ceil(diffY) : Math.floor(diffY) )
       };
    }
    */

    return MouseAnims;

});

