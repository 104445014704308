define('views/ArticleView',[
    'views/PageView',
    'text!templates/article.html',
    'lodash',
    'app',
    'mod/Slider'
], function (PageView, tmpl, _, app, Slider) {

    'use strict';

    return PageView.extend({

        template: _.template(tmpl),

        initialize: function (args) {
            var slug = args[0];
            this.article = _.find(app.articles, {slug: slug});

            // if article doesn't exist
            if (!this.article) {
                app.router.notFound();
            }

            // adjacent articles
            var i = app.articles.indexOf(this.article);
            this.prev = i !== 0 ? app.articles[i - 1] : null;
            this.next = i + 1 < app.articles.length ? app.articles[i + 1] : null;
      
        },

        render: function () {
            this._render();

            var $slider = this.$el.find('.flexslider');
            if ($slider.length) {
                this.slider = new Slider($slider);
            }
            
            //document.getElementById('fbShare').href="https://www.facebook.com/sharer/sharer.php?u=" + curURL;            

        },

        parseContent: function (html) {
            return html.replace(/<img.*?src="(.*?)".*?>/i, function (match, src) {
               return '<div class="row"><div class="jumbotron small">' + app.deferBkImg(src) + '</div></div>';
            });
        },

        remove: function () {
            if (this.slider) {
                this.slider.destroy();
            }
            this._remove();
        }

    });

});

