/*===================================
=            Header View            =
===================================*/

define('views/HeaderView',[
    'views/ComponentView',
    'text!templates/header.html',
    'lodash',
    'app'
], function (ComponentView, tmpl, _, app) {

    'use strict';

    return ComponentView.extend({

        tagName: 'header',

        template: _.template(tmpl),

        events: {
            'click a:not(.logo)'        : 'navToggle',
            'click a.logo'              : 'clickLogo',
            'click .nav-close'          : 'hideNav'
        },

        initialize: function () {
            this._render();
        },

        showNav: function () {
            this.$el.addClass('active');
        },

        hideNav: function () {
            this.$el.removeClass('active');
        },

        clickLogo: function () {
            if (this.$el.hasClass('active')) {
                this.navToggle();
            }
        },

        navToggle: function () {
            if (this.$el.hasClass('active')) {
                this.hideNav();
            } else {
                this.showNav();
            }
        },

        highlight: function () {
            // extract parent path
            var href = app.fragment(window.location.href),
                path = href.replace(/^\//i, '').replace(/\/.*$/i, '');

            this.$el.find('.nav-list a span').removeClass('underline');
            this.$el.find('.nav-list a[href="' + app.url(path) +'"] span').addClass('underline');
        }

    });

});

