define('views/ContactFormView',[
    'views/FormView',
    'models/Contact',
    'autosize',
    'text!templates/contact-form.html',
    'text!templates/contact-form-modal.html',
    'app',
    'lib/Modal',
    'lodash',
    'backbone'
], function (FormView, Contact, autosize, tmpl, modalTmpl, app, Modal, _, Backbone) {

    'use strict';

    return FormView.extend({

        template: _.template(tmpl),

        modalTemplate: _.template(modalTmpl),

        initialize: function () {
            this.setElement(this.template(app));
            this.model = new Contact();
            this.modal = new Modal({
                hash: ['#confirm'],
                html: this.modalTemplate(app),
                classnames: 'medium'
            });
            autosize(this.$el.find('textarea.autosize'));
        },

        onSuccess: function () {
            this.modal.show();
        },

        onFail: function () {

        },

        remove: function () {
            this.modal.destroy();
            Backbone.View.prototype.remove.call(this);
        }

    });

});

