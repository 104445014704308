define('lib/Modal',[
    'lodash'
], function (_) {

    'use strict';


    var html =  '<div class="modal-container">' +
                    '<div class="modal-shadow"></div>' +
                    '<div class="modals"></div>' +
                '</div>';

    var $container, $modals, $shadow;

    var modals = [];

    function init () {
        $container = $(html);
        $(document.body).append($container);

        $modals = $container.find('.modals');
        $shadow = $container.find('.modal-shadow');

        // click shadow to hide all modals
        $shadow.click(function () {
            hideAll();
        });
    }

    function hideAll () {
        _.filter(modals, {on: true}).forEach(function (modal) {
            modal.hide();
        });
    }

    var defaults = {
        show: false,
        hash: [], // string or array
        classnames: '',
        html: ''
    };

    function Modal (opts) {
        this.opts = _.extend({}, defaults, opts);
        this.parseHash();

        // initialize
        if (!$container) {
            init();
        }

        // modal element
        this.$el = $('<div class="modal ' + this.opts.classnames + '"></div>');
        this.$el.on('click', '.close', this.hide.bind(this));
        $modals.append(this.$el);

        // display modal if needed
        if (this.opts.show || this.opts.hash.indexOf(window.location.hash) > -1) {
            this.show(this.opts.html);
        }

        modals.push(this);
    }

    Modal.prototype = {

        $el: null,

        on: false,

        show: function (html) {
            hideAll();

            this.$el.append(html ? html : this.opts.html);
            $shadow.show();
            this.$el.show();
            this.on = true;
        },

        hide: function () {
            this.$el.hide();
            $shadow.hide();
            this.$el.html('');
            this.on = false;
        },

        parseHash: function () {
            if (typeof this.opts.hash === 'string') {
                this.opts.hash = [this.opts.hash];
            }

            this.opts.hash = _.map(this.opts.hash, function (h) {
                return ( !h.match(/^#/) ? '#' : '' ) + h;
            });
        },

        destroy: function () {
            if (this.on) {
                this.hide();
            }
            this.$el.remove();
            modals.splice(modals.indexOf(this), 1);
        }

    };

    return Modal;

});

