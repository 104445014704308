/*==============================
=            Routes            =
==============================*/

define('routers/routes',[
    'views/IntroView',
    'views/AboutView',
    'views/ProjectsView',
    'views/ProjectView',
    'views/ContactView',
    'views/ArticlesView',
    'views/ArticleView',
    'views/ServicesView',
    'views/SocialView',
    'views/PastView',
    'views/CollabsView',
    'views/CollabView'    
    
    
], function () {

    'use strict';

    return {
        
        ''              : arguments[0], // home
        'about'         : arguments[1],
        'work'          : arguments[2],
        'work/:project' : arguments[3],
        'contact'       : arguments[4],
        'blog'          : arguments[5],
        'blog/:article' : arguments[6],
        'services'      : arguments[7],
        'social'        : arguments[8],
        'past'          : arguments[9],
        'industries'    : arguments[10],
        'industries/:collab'    : arguments[11]
        
        
    };

});
