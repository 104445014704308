/*==================================
=            App Object            =
==================================*/

define('app',[
	'lib/config',
	'lib/helpers',
	'lodash'
], function (config, helpers, _) {

    'use strict';

    window.app = _.extend({}, config, helpers);

    return window.app;

});

