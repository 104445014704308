/*=====================================
=            Projects View            =
=====================================*/

define('views/ArticlesView',[
    'views/PageView',
    'text!templates/articles.html',
    'lodash',
    'lib/MouseAnims',
    'app',
    'mod/Slider',    
    'salvattore'    
], function (PageView, tmpl, _, MouseAnims) {

    'use strict';

    function setTranslate(el, x, y) {
        el.setAttribute(
            'style',
            '-webkit-transform: translate(' + x + ',' + y + ');' +
            'transform: translate(' + x + ',' + y + ');'
        );
    }

    return PageView.extend({

        template: _.template(tmpl),

        events: {
            'mouseenter a': 'onHoverin',
            'mouseleave a': 'onHoverout'
        },

        initialize: function (args) {
            var slug = args[0];
            this.article = _.find(app.article, {slug: slug});
        },

        render: function () {
            this._render();
            this.mouse = new MouseAnims();
        },

        onHoverin: function (event) {
            console.log("hoverin");
            var a = event.currentTarget,
                $bgs = $(a).find('.double-reflect > *');

            $(a).removeClass('release');

            a.mouseAnim = this.mouse.add(function (move, anim) {
                var pos = anim.mousePos(),
                    x = pos.x * 2 / anim.rect.width - 1,
                    y = pos.y * 2 / anim.rect.height - 1;

                setTranslate($bgs[0], (-x * 2) + '%', (-y * 2) + '%');
                setTranslate($bgs[1], (x) + '%', (y) + '%');
            }, a);
        },

        onHoverout: function (event) {
            var a = event.currentTarget;
            this.mouse.remove(a.mouseAnim);
            $(a).addClass('release');
        },

        remove: function () {
            if (this.mouseAnim) {
                this.mouseAnim.destroy();
            }
            this._remove();
        }


    });

});

