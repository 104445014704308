/*==================================
=            Intro View            =
==================================*/

define('views/IntroView',[
    'views/PageView',
    'text!templates/intro.html',
    'lodash',
    // 'lib/Lines1'
    'lib/MouseAnims',
    'app',
    'mod/Slider',    
    'salvattore'     
], function (PageView, tmpl, _, MouseAnims) {

    'use strict';

    function setTranslate(el, x, y) {
        el.setAttribute(
            'style',
            '-webkit-transform: translate(' + x + ',' + y + ');' +
            'transform: translate(' + x + ',' + y + ');'
        );
    }
    
    return PageView.extend({

        template: _.template(tmpl),

        events: {
            'mouseenter a.brick.onhover': 'onHoverin',
            'mouseleave a.brick.onhover': 'onHoverout'
        },

        render: function () {
            this._render();
            this.mouse = new MouseAnims();
        },

        onHoverin: function (event) {
            console.log("hoverin");
            var a = event.currentTarget,
                $bgs = $(a).find('.double-reflect > *');

            $(a).removeClass('release');

            a.mouseAnim = this.mouse.add(function (move, anim) {
                var pos = anim.mousePos(),
                    x = pos.x * 2 / anim.rect.width - 1,
                    y = pos.y * 2 / anim.rect.height - 1;

                setTranslate($bgs[0], (-x * 2) + '%', (-y * 2) + '%');
                setTranslate($bgs[1], (x) + '%', (y) + '%');
            }, a);
        },

        onHoverout: function (event) {
            var a = event.currentTarget;
            this.mouse.remove(a.mouseAnim);
            $(a).addClass('release');
        },

        remove: function () {
            if (this.mouseAnim) {
                this.mouseAnim.destroy();
            }
            this._remove();
        }
        
        
        /*
        events: {
            'mouseover [data-graph]': 'shift',
            'mouseleave [data-graph]': 'shiftDefault'
        },

        render: function () {
            this._render();
            this.anim = window.lines = new Lines1(this.$el.find('#intro-anims')[0]);
        },

        transition: function () {
            this.render();
            this.t = setTimeout(function () {
                this.shiftDefault();
            }.bind(this), 2000);

            return this.timeoutDefer(0);            
        },

        shift: function (event) {
            var graph = event.currentTarget.getAttribute('data-graph');
            clearTimeout(this.t);
            this.anim.shift(graph);
        },

        shiftDefault: function () {
            this.t = setTimeout(function () {
                this.anim.shift(7);
            }.bind(this), 500);
        },

        remove: function () {
            clearTimeout(this.t);
            this.anim.destroy();
            this._remove();
        }
        */

    });

});
