define('mod/Slider',[
	'lib/MouseAnims',
	'flexslider'
], function (MouseAnims) {

	'use strict';

	function Slider (el) {
		this.$el = $(el);
		this.el = this.$el[0];
        this.nav = this.$el.find('.flex-directional-nav > *')[0];

        this.mouse = new MouseAnims();
        this.slider = this.$el.flexslider({
            directionNav: false,
            animation: 'slide',
            slideshow: false
        });

        this.el.addEventListener('mouseenter', this.showNav.bind(this));
        this.el.addEventListener('mouseleave', this.hideNav.bind(this));
        this.nav.addEventListener('click', this.click.bind(this));
	}

	Slider.prototype = {
		showing: null,
		showNav: function () {
			this.mouseAnim = this.mouse.add(function (move, anim) {
				var mouse = anim.mousePos(),
					dir = mouse.x / anim.rect.width > 0.5 ? 'right' : 'left';

				if (this.showing !== dir) {
					this.nav.setAttribute('class', 'icon-arrow-' + dir);
					this.showing = dir;
				}

				setTranslate(this.nav, mouse.x - 32 + 'px', mouse.y - 32 + 'px');
			}.bind(this), this.el);
		},
		hideNav: function () {
			this.showing = null;
			this.nav.setAttribute('class', 'hide');
			this.mouse.remove(this.mouseAnim);
		},
		click: function () {
            var nav = this.nav;

			this.$el.flexslider(this.showing === 'left' ? 'prev' : 'next');
            nav.className = nav.className.replace(' active', '');
            setTimeout(function () {
                nav.className += ' active';
            }, 0);
		},
		destroy: function () {
			this.mouse.destroy();
		}
	};

    function setTranslate(el, x, y) {
        el.setAttribute(
            'style',
            '-webkit-transform: translate(' + x + ',' + y + ');' +
            'transform: translate(' + x + ',' + y + ');'
        );
    }

	return Slider;

});

