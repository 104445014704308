define('jquery', [], function () {
    'use strict';
    return window.jQuery;
});

require.config({
    baseUrl: '/assets/js',
    paths: {
        // requirejs plugins
        'domReady'      : '../components/requirejs-domready/domReady',
        'text'          : '../components/requirejs-plugins/lib/text',

        // shortcuts
        'app'           : 'lib/app',

        // third party components
        'lodash'        : '../components/lodash/lodash',
        'backbone'      : '../components/backbone/backbone',
        'zeotrope'      : '../components/zeotrope.js/dist/zeotrope',
        'flexslider'    : '../components/flexslider/jquery.flexslider',
        'salvattore'    : '../components/salvattore/dist/salvattore',
        'juicer'        : '../components/juicer/juicer',
        'autosize'      : '../components/autosize/dist/autosize'
    },
    map: {
        '*': {
            'underscore': 'lodash'
        }
    },
    shim: {
        'zeotrope': {
            exports: 'Zeotrope'
        }
    },
    deps: ['start/app']
});
define("mc2", function(){});

