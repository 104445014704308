/*==================================
=            Inline SVG            =
==================================*/


define('lib/svg',[
    'text!svgs/logo.svg',
    'text!svgs/sq.svg'
], function () {

    'use strict';

    return {
        'logo'	: arguments[0],
        'sq'	: arguments[1]
    };

});
