/*==================================
=            About View            =
==================================*/

define('views/AboutView',[
    'views/PageView',
    'text!templates/about.html',
    'lodash'
], function (PageView, tmpl, _) {

    'use strict';

    return PageView.extend({

        template: _.template(tmpl)

    });

});
