/*===================================
=            Footer View            =
===================================*/

define('views/FooterView',[
    'backbone',
    'text!templates/footer.html',
    'lodash',
    'app',
    'views/SubscribeFormView'
], function (Backbone, tmpl, _, app, SubscribeFormView) {

    'use strict';

    return Backbone.View.extend({

        tagName: 'footer',

        template: _.template(tmpl),

        initialize: function () {
            this.$el.html(this.template(app));
            this.form = new SubscribeFormView({
                el: this.$el.find('form')
            });
        }

    });

});

