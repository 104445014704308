/*======================================
=            Initialize App            =
======================================*/

define('start/app',[
    'domReady!',
    'app',
    'views/AppView',
    'routers/AppRouter'
], function (doc, app, AppView, AppRouter) {

    'use strict';

    app.view = new AppView();
    app.router = new AppRouter();

});
