define('models/Contact',[
    'models/Validatable'
], function (Validatable) {

    'use strict';

    return Validatable.extend({

        validations: {
            name: ['required'],
            phone: ['phone'],
            email: ['required', 'email'],
            message: ['max:255']
        }

    });

});
