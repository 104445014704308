/*==================================
=            Social View            =
==================================*/

define('views/SocialView',[
    'views/PageView',
    'text!templates/social.html',
    'lodash',
    'app'
], function (PageView, tmpl, _, app) {

    'use strict';    

    return PageView.extend({

        template: _.template(tmpl),

        initialize: function () {
            this.social = app.social;
            }        

    });

});




