/*==================================================
=            Form View with Validations            =
==================================================*/

define('views/FormView',[
    'backbone',
    'lodash'
], function (Backbone, _) {

    'use strict';

    return Backbone.View.extend({

        events: {
            // 'click [type="submit"]': '_onSubmit',
            'submit': '_onSubmit',
            'input input,textarea': 'onChange',
            'change input[type="checkbox"]': 'onChange',
            'change input,textarea': 'onEdited'
        },

        /**
         * model instance
         * @type {Backbone.Model|Validatable}
         */
        model: null,

        /**
         * Input change handler
         * @param  {jQuery.Event}   event
         */
        onChange: function (event) {
            var input = event.currentTarget,
                value = this.getVal(input);

            this.model.set(input.name, value);

            // only show error if there are validation rules for this input and...
            // user has finished editing (blur the input)
            if (this.model.validations[input.name] && input.className.indexOf('touched') > -1) {
                var err = this.model.test(input.name);
                this.updateValidationErr(input, err);
            }
        },

        onEdited: function (event) {
            var $input = $(event.currentTarget);
            $input.addClass('touched');
            this.onChange(event);
        },

        /**
         * retrieve input value
         * @param  {element}    input
         * @return {string}
         */
        getVal: function (input) {
            if (input.getAttribute('type') === 'checkbox') {
                return input.checked;
            } else {
                return input.value;
            }
        },

        /**
         * onSubmit handler
         * @param  {jQuery.Event}   event
         */
        _onSubmit: function (event) {
            this.onSubmit(event);

            event.preventDefault();

            // update model
            var values = this.$el.serializeArray(),
                attrs = {};
            $.each(values, function (i, val) {
                attrs[val.name] = val.value;
            });
            this.model.set(attrs);

            // validation
            if (this.model.isValid()) {
                // submit ajaxly if valid
                var xhr = $.ajax({
                    url: this.el.getAttribute('action'),
                    type: this.el.getAttribute('method'),
                    data: values
                });

                // callbacks
                xhr.done(this._onSuccess.bind(this));
                xhr.fail(this._onFail.bind(this));
            } else {
                // invalid
                this.showFormValidationErrs();
            }
        },

        onSubmit: function () {},

        updateValidationErr: function (input, err) {
            var errTxt = err && err.length ? err[0] : '';

            // no need to update if the error text is the same
            if (input.validationErr === errTxt) {
                return;
            } else {
                input.validationErr = errTxt;
            }

            // update err text
            var $input = $(input),
                $formGroup = $input.parents('.form-group'),
                $errBk = $formGroup.find('.help-block');

            if ($errBk.length) {
                $errBk.html(errTxt);
            }

            // validation status class
            if ($formGroup) {
                if (errTxt) {
                    $formGroup.addClass('has-error');
                } else {
                    $formGroup.removeClass('has-error');
                }
            }
        },

        showFormValidationErrs: function () {
            var errs = this.model.validationError;

            $.each(this.model.validations, function (attr) {
                if (errs[attr]) {
                    var $input = this.$el.find('[name="' + attr + '"]');
                    $input.addClass('touched'); // force show the errors
                    this.updateValidationErr($input[0], errs[attr]);
                }
            }.bind(this));

            // alert submit button
            var $submit = this.$el.find('[type="submit"]');
            $submit.removeClass('has-error');
            setTimeout(function () {
                $submit.addClass('has-error');
            }, 0);
        },

        onSuccess: function () {},

        onFail: function () {},

        _onSuccess: function (res, status, xhr) {
            this.model.clear();
            this.clearInputs();
            this.onSuccess(res, status, xhr);
        },

        _onFail: function (xhr) {
            var res = xhr.responseJSON;

            // validation errors
            if (res) {
                this.parseErrs(res);
                this.showFormValidationErrs();
            }
            // try to submit the form without ajax
            else {
                this.$el.unbind('submit'); // unbind the submit event to avoid event loop
                this.$el.submit();
            }
        },

        /**
         * Parse validation errors from server response
         * @param  {object}     res     Server response
         */
        parseErrs: function (res) {
            // insert exceptional errors
            _.each(res, function (errs, name) {
                this.model.addErr(name, errs[0]);
            }, this);

            // run the validation again to update status
            this.model.isValid();
        },

        clearInputs: function () {
            this.$el.find('input:not([type="hidden"])').val('');
            this.$el.find('[type="submit"]').removeClass('has-error');
        }

    });

});

