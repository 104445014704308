define('views/ComponentView',['backbone', 'app'], function (Backbone, App) {

    'use strict';

    return Backbone.View.extend({


        sequenceIntvl: 100,

        /**
         * shortcut for default render method
         */
        _render: function () {
            if (this.template) {
                this.$el.html(this.template(App));
            }
        },

        /**
         * shortcut for default remove method
         */
        _remove: function () {
            Backbone.View.prototype.remove.call(this);
        },

        sequenceTrans: function () {
            var t = 20,
                $items = this.$el.find('.sequence-trans');

            // restart tranitions
            $items.removeClass('sequence-in');

            $items.each(function (i, e) {
                setTimeout(function () {
                    $(e).addClass('sequence-in');
                }, t);
                t += this.sequenceIntvl;
            }.bind(this));
        }

    });

});
