/*======================================
=            Base Page View            =
======================================*/

define('views/PageView',[
    'views/ComponentView',
    'app'
], function (ComponentView, app) {

    'use strict';

    return ComponentView.extend({

        tagName: 'article',

        className: 'page',

        transDuration: 2000,

        /**
         * Render template if there is one
         */
        render: function () {
            this._render();
            this.sequenceTrans();
        },


        /**
         * Default transition method
         * @return {jQuery.Deferred}
         */
        transition: function () {
            app.view.$el.addClass('is-entering');

            this.timeoutDefer(this.transDuration).done(function () {
                app.view.$el.removeClass('is-entering');
            }.bind(this));

            var renderDefer = this.timeoutDefer(this.transDuration / 4);
            renderDefer.done(function () {
                window.scrollTo(0, 0);
                this.render();
            }.bind(this));

            return renderDefer;
        },

        /**
         * Create a timeout defer object
         * @param  {integer}    duration
         * @return {jQuery.Deferred}
         */
        timeoutDefer: function (duration) {
            var defer = $.Deferred();
            setTimeout(function () {
                defer.resolve();
            }, duration);
            return defer;
        }

    });

});

