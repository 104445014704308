define('models/Subscrs',[
    'models/Validatable'
], function (Validatable) {

    'use strict';

    return Validatable.extend({

        validations: {
            email: ['required', 'email']
        }

    });

});
