/*=====================================
=            Services View            =
=====================================*/

define('views/ServicesView',[
    'views/PageView',
    'text!templates/services.html',
    'lodash'
], function (PageView, tmpl, _) {

    'use strict';

    return PageView.extend({

        template: _.template(tmpl)

    });

});

