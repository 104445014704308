define('views/CollabView',[
    'views/PageView',
    'text!templates/collab.html',
    'lodash',
    'app',
    'mod/Slider',    
    'salvattore'
], function (PageView, tmpl, _, app, Slider, salvattore) {

    'use strict';

    return PageView.extend({

        template: _.template(tmpl),

        initialize: function (args) {
            var slug = args[0];
            this.collab = _.find(app.collabs, {slug: slug});

            // if collab doesn't exist
            if (!this.collab) {
                console.log("collab doesn't exist")
                app.router.notFound();
            } else {
                console.log("collab exists")
            }

            // adjacent collabs
            var i = app.collabs.indexOf(this.collab);
            this.prev = app.collabs[ i <= 0 ? app.collabs.length - 1 : i - 1 ];
            this.next = app.collabs[ (i + 1) % app.collabs.length ];
        },

        render: function () {
            this._render();

            var $slider = this.$el.find('.flexslider');
            if ($slider.length) {
                this.slider = new Slider($slider);
            }

            //salvattore.registerGrid(this.$el.find('.grid[data-columns]')[0]);

            // force all video to play
            this.$el.find('video').each(function () {
                this.play();
            });            
        },


        
        parseContent: function (html) {
            return html.replace(/<img.*?src="(.*?)".*?>/i, function (match, src) {
               return '<div class="row"><div class="jumbotron small">' + app.deferBkImg(src) + '</div></div>';
            });
        },

        remove: function () {
            if (this.slider) {
                this.slider.destroy();
            }
            this._remove();
        }

    });

});

