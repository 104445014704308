/*================================
=            App View            =
================================*/

define('views/AppView',[
    'backbone',
    'app',
    'views/HeaderView',
    'views/FooterView'
], function (Backbone, App, HeaderView, FooterView) {

    'use strict';

    return Backbone.View.extend({

        el: $('#app'),

        transMode: true, // initial render will have transition
 
        transDefer: null,

        currentView: null,

        events: {
            'click a:not([target])'     : 'clickLink',
            'mouseenter a:not([target])': 'preload'
        },

        initialize: function () {
            this.header = new HeaderView();
            this.footer = new FooterView();
            this.$content = $('<div id="content"></div>');

            this.$el.append(this.header.el, this.$content, this.footer.el);
        },

        clickLink: function (event) {
            var href = event.currentTarget.href;
            if (App.router && href) {
                this.transMode = true; // only link clicks will trigger transitions
                App.router.goTo(href);
                event.preventDefault();
            }
        },

        preload: function () {},

        render: function (View, args) {
            var target = new View(args);

            // render target view
            this.$content.append(target.el);
            this.transition(target);
            this.header.highlight();

            this.currentView = target;
        },

        transition: function (target) {
            var current = this.currentView;

            // force to resolve any unfinished transition
            if (this.transDefer) {
                this.transDefer.resolve();
            }

            if (this.transMode && target.transition) {
                this.transDefer = target.transition();

                // remove current view after transition
                if (current) {
                    this.transDefer.done(function () {
                        current.remove();
                        target.render();
                    });
                }
            } else {
                if (current) {
                    current.remove();
                }
                this.transDefer = null;
                target.render();
            }

            this.transMode = false;
        }

    });

});
