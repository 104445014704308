define('views/ProjectView',[
    'views/PageView',
    'text!templates/project.html',
    'lodash',
    'app',
    'mod/Slider',    
    'salvattore'
], function (PageView, tmpl, _, app, Slider, salvattore) {

    'use strict';

    return PageView.extend({

        template: _.template(tmpl),

        initialize: function (args) {
            var slug = args[0];
            this.project = _.find(app.projects, {slug: slug});

            // if project doesn't exist
            if (!this.project) {
                app.router.notFound();
            }

            // adjacent projects
            var i = app.projects.indexOf(this.project);
            this.prev = app.projects[ i <= 0 ? app.projects.length - 1 : i - 1 ];
            this.next = app.projects[ (i + 1) % app.projects.length ];
        },

        render: function () {
            this._render();

            var $slider = this.$el.find('.flexslider');
            if ($slider.length) {
                this.slider = new Slider($slider);
            }

            //salvattore.registerGrid(this.$el.find('.grid[data-columns]')[0]);

            // force all video to play
            this.$el.find('video').each(function () {
                this.play();
            });            
        },


        
        parseContent: function (html) {
            return html.replace(/<img.*?src="(.*?)".*?>/i, function (match, src) {
               return '<div class="row"><div class="jumbotron small">' + app.deferBkImg(src) + '</div></div>';
            });
        },

        remove: function () {
            if (this.slider) {
                this.slider.destroy();
            }
            this._remove();
        }

    });

});

