/*==================================
=            App Router            =
==================================*/

define('routers/AppRouter',[
    'backbone',
    'app',
    'routers/routes',
    'routers/filters'
], function (Backbone, App, routes, filters) {

    'use strict';

    return Backbone.Router.extend({

        routes: routes,
        
        initialize: function () {
            // get origin
            var origin = window.location.origin;
            if (!origin) {
                // for ie
                origin = window.location.protocol + '//' +
                window.location.hostname +
                (window.location.port ? ':' + window.location.port: '');
            }

            // starting history API
            var root = App.siteURL.replace(origin, '');
            if (root.charAt(0) !== '/') {
                root = '/' + root;
            }
            if (root.slice(-1)) {
                root += '/';
            }
            Backbone.history.start({
                pushState: true,
                hashChange: false,
                root: root
            });
        },

        /**
         * Navigate to given url
         * @param  {string} href    full URL or fragment
         */
        goTo: function (href) {
            this.navigate(App.fragment(href), {trigger: true});
        },

        /**
         * Default route
         */
        notFound: function () {
            this.navigate('', {trigger: true});
        },

        execute: function (callback, args) {
            if (!callback) {
                this.notFound();
            }

            // route filter
            if (callback.filter) {
                args = filters[callback.filter](args);
                if (args === false) {
                    this.notFound();
                }
            }

            // render view or execute callback
            if (callback.extend) {
                App.view.render(callback, args);
            } else {
                callback.apply(this, args);
            }
        }

    });

});
