/*
|--------------------------------------------------------------------------
| Validatable Model
|--------------------------------------------------------------------------
|
*/

/**
 * TODO when needed: Validations that can only be done in server, such as unique email in database
 */

define('models/Validatable',['backbone', 'lodash'], function (Backbone, _) {

    'use strict';

    return Backbone.Model.extend({

        validationError: {},

        /**
         * Validate model attributes according to the validation config
         * @param  {object}     attrs
         * @return {object}     errors message object if invalid
         */
        validate: function () {
            var valid = true,
                errs = {};

            // iterate attrs
            _.each(this.validations, function (rules, attr) {
                var err = this.test(attr);
                if (err) {
                    valid = false;
                    errs[attr] = err;
                }
            }, this);

            if (!valid) {
                return errs;
            }
        },

        /**
         * Test if a attribute is valid
         * @param  {string}     attr    attribute name
         * @return {object}     err object if invalid
         */
        test: function (attr) {
            var validation = this.validations[attr],
                value = this.get(attr),
                errs = [];

            if (!validation) {
                return;
            }

            // check exceptional errors
            var exception = this.inErrs(attr, value);
            if (exception) {
                errs.push(exception.err);
            }

            // iterate rules
            _.each(validation, function (rule) {
                // get rule name and arguments
                var args        = rule.split(/[:,]/),
                    ruleName    = args.shift();

                // run the test
                var test = this.testRuleOnFilled(ruleName, value, args);
                if (typeof test === 'string') {
                    errs.push(test);
                }
            }, this);


            if (errs.length) {
                return errs;
            }
        },

        /**
         * Wrapper function of testRule that return true if value is empty, except for the required rule
         * @param  {string}             ruleName
         * @param  {string|number}      value
         * @param  {array}              args
         * @return {boolean|object}                 true or error message object
         */
        testRuleOnFilled: function (ruleName, value, args) {
            if (ruleName !== 'required' && this.testRule('required', value) !== true) {
                return true;
            } else {
                return this.testRule(ruleName, value, args);
            }
        },

        /**
         * Detect and run rules againist value given
         * @param  {string}             ruleName
         * @param  {string|number}      value
         * @param  {array}              args
         * @return {boolean|object}                 true or error message object
         */
        testRule: function (ruleName, value, args) {
            var rule = this.rules[ruleName];
            args = args || [];

            if (typeof rule === 'function') {
                args.unshift(value);
                return rule.apply(this, args);
            } else if (rule instanceof RegExp) {
                // non camelcase ruleName
                var name = ruleName.replace(/([A-Z])/g, ' $1').toLowerCase();

                return rule.test(value) ? true : 'Invalid ' + name;
            }
        },

        /**
         * Validation config
         * @type {Object}
         */
        validations: {
            // examples:
            // email:   ['required', 'email'],
            // zipcode: ['digit:5']
        },

        /**
         * Validation Rules
         * @type {Object}
         * Rules can be in two types: RegExp and function
         * Function rules need to return true when value is valid and error message string when invalid
         * Arguments will be passed to function rules as this:
         * function ruleName (values, [arg0, arg1...]) {}
         */
        rules: {
            required: function (value) {
                return value || value === false || value === 0 ? true : 'Required';
            },
            digit: function (value, length) {
                value = value.toString();
                var isDigits = !/\D/.test(value),
                    matchLength = length ? value.length === length : true;

                return isDigits && matchLength ? true : 'Must be ' + (length || '') + 'digits';
            },
            max: function (value, length) {
                return value.length <= length ? true : length + ' characters max';
            },
            /*
            unique: function () {},
            */

            // For more regex, see: http://www.virtuosimedia.com/dev/php/37-tested-php-perl-and-javascript-regular-expressions
            email: /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
            usStates: /^(?:A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|PA|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY])*$/i,
            zipcode: /^([0-9]{5}(?: *- *[0-9]{4})?)*$/,
            phone: /^((([0-9]{1})*[- .(]*([0-9]{3})[- .)]*[0-9]{3}[- .]*[0-9]{4})+)*$/
        },

        /**
         * Place to store exceptional errors
         * @type {Object}
         */
        _errs: {},

        /**
         * Add Exceptional Error specific to a value
         * @param {string}          attr
         * @param {string}          err
         * @param {string|number}   value   optional, default is the current value
         */
        addErr: function (attr, err, value) {
            if (this._errs[attr] === undefined) {
                this._errs[attr] = [];
            }
            this._errs[attr].push({
                err: err,
                value: value !== undefined ? value : this.get(attr)
            });
        },

        /**
         * Check if value has exceptional error
         * @param {string}          attr
         * @param {string|number}   value
         * @return {boolean|string}
         */
        inErrs: function (attr, value) {
            var errs = this._errs[attr];

            if (errs) {
                var err = _.find(errs, {value: value});
                if (err) {
                    return err;
                }
            }

            return false;
        }

    });

});

