/*========================================
=            Template Helpers            =
========================================*/

define('lib/helpers',['lodash', 'lib/svg'], function (_, svg) {

    'use strict';

    var helpers = {};

    /**
     * Lodash
     */
    helpers._ = _;

    /**
     * Generate a full URL from fragment
     * @param  {string} fragment
     * @return {string}
     */
    helpers.url = function (fragment) {
        // default
        if (!fragment) {
            return this.siteURL;
        }

        // external links
        if (fragment.match(/^http/)) {
            return fragment;
        }

        // remove root slash
        fragment = fragment.replace(/^\//, '');

        return !/^http/.test(fragment) ? this.siteURL + '/' + fragment : fragment;
    };

    /**
     * Extract fragment from URL if siteURL is matched
     * @param  {string} url
     * @return {string}
     */
    helpers.fragment = function (url) {
        return url.replace(this.siteURL, '').replace(/^\//, '');
    };

    /**
     * Limit the number of characters in a string
     * @param  {string}     str
     * @param  {integer}    limit
     * @param  {string}     [end=...]   string to append if str exceed the limit
     * @return {string}
     */
    helpers.limit = function (str, limit, end) {
        return str.length >limit ? str.slice(0, limit) + (end === undefined ? '...' : end) : str;
    };

    /**
     * Inline SVG
     * @param  {string} name
     * @return {string}
     */
    helpers.svg = function (name) {
        return svg[name];
    };

    /*==========  Deferred and preload assets  ==========*/

    var cached = {
        images: []
    };

    /**
     * Prelaod image with given url
     * @param  {string} src
     * @return {[type]}     [description]
     */
    function preload (src) {
        if (cached.images.indexOf(src) === -1) {
            cached.images.push(src);
            var img = new Image();
            img.src = src;
        }
    }

    var deferImgTmpl = _.template(
        '<div class="defer-img<%= classNames %>">' +
            '<img onload="this.className = \'loaded\';" src="<%= src %>">' +
            '<div style="padding-bottom: <%= height %>%;"></div>' +
        '</div>'
    );

    /**
     * Generate defer image html
     * @param  {string}     src
     * @param  {string}     aspectRatio     example: '1:2'
     * @param  {string}     className
     * @return {string}
     */
    helpers.deferImg = function(src, aspectRatio, className) {
        src = this.url(src);
        preload(src);

        var AR = aspectRatio.split(':'),
            params = {
            src: src,
            classNames: className ? ' ' + className : '',
            height: AR[1] * 100 / AR[0]
        };

        return deferImgTmpl(params);
    };

    var deferBkImgTmpl = _.template(
        '<div class="defer-bk-img<%= classNames %>">' +
            '<div class="loading"></div>' +
            '<img src="<%= src %>" onload="' +
                'this.parentElement.style.backgroundImage = \'url(\\\'<%= src %>\\\')\'; ' +
                'this.parentNode.innerHTML = \'\';">' +
        '</div>'
    );

    /**
     * Generate defer background image
     * @param  {string}     src
     * @param  {string}     className
     * @return {string}
     */
    helpers.deferBkImg = function (src, className) {
        src = this.url(src);
        preload(src);

        return deferBkImgTmpl({
            src: src,
            classNames: className ? ' ' + className : ''
        });
    };

    /**
     * wrap every character in the string with span
     * @param  {string}     str
     * @param  {array}      classes
     * @return {string}
     */
    helpers.spanChar = function (str, classes) {
        var html = '',
            classname = null;

        for (var i = 0; i < str.length; i++) {
            if (classes) {
                classname = classes[ Math.round(Math.random() * (classes.length-1)) ];
                classname = ' class="' + classname + '"';
            }

            html += '<span' + (classname ? classname : '') + '>' + str.charAt(i) + '</span>';
        }

        return html;
    };

    return helpers;

});

