/*==================================
=            Past View            =
==================================*/

define('views/PastView',[
    'views/PageView',
    'text!templates/past.html',
    'lodash',
    'app',
    'mod/Slider',    
    'salvattore'
], function (PageView, tmpl, _, app, Slider, salvattore) {

    'use strict';

    return PageView.extend({

        template: _.template(tmpl),
        
        render: function () {
            this._render();

            var $slider = this.$el.find('.flexslider');
            if ($slider.length) {
                this.slider = new Slider($slider);
            }

            // force all video to play
            this.$el.find('video').each(function () {
                this.play();
            });            
        },

        
        parseContent: function (html) {
            return html.replace(/<img.*?src="(.*?)".*?>/i, function (match, src) {
               return '<div class="row"><div class="jumbotron small">' + app.deferBkImg(src) + '</div></div>';
            });
        },
        
        
        remove: function () {
            if (this.slider) {
                this.slider.destroy();
            }
            this._remove();
        }

    });

});
